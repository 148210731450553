import React from 'react';
import Layout from '../components/layout';
import YearComponent from '../components/YearComponent';

const Year = ({ pageContext }) => {
    const pageTitle = `${pageContext.kernel_name} in ${pageContext.lang.name.charAt(0).toUpperCase()+pageContext.lang.name.slice(1)}`;
    return (
        <Layout menuList={[pageContext.lang.name]} pageTitle={pageTitle} pageLang={pageContext.lang}>
            <YearComponent yearContent={pageContext.content} pageTitle={pageTitle} language={pageContext.lang.name} />
        </Layout>
    );
};

export default Year;
