import React from 'react'
import { Link } from 'gatsby';

const yearBut = {
    border: '1px solid #ddd',
    borderRadius: '5px',
    margin: '5px',
    padding: '5px',
    userSelect: 'none',
    color: '#000'
}

const YearComponent = ({yearContent, pageTitle, language}) => {
  return (
    <div>
        <div className="col-md-12">
            <h1 style={{marginTop: '30px'}}>{pageTitle}</h1>
        </div>
        <div className="row">
            {yearContent.map(elem => (
                <div key={Date.now()+Math.random()} className="col-sm-6">
                    <Link style={{textDecoration: 'none'}} to={'/'+language+'/'+elem+'/'}>
                        <div style={yearBut}>{elem}</div>
                    </Link>
                </div>
            ))}
        </div>
    </div>
  )
}

export default YearComponent;